import { Controller } from "stimulus";
import "bootstrap-select";

export default class extends Controller {
  connect() {
    $(this.element).selectpicker({
      liveSearch: true,
      iconBase: "",
    });
  }
}
