import { Controller } from "stimulus";
import morphdom from "morphdom";

export default class extends Controller {
  morphFromAjax(event) {
    const newHTML = event.detail[2].responseText;
    const fragment = this.parseHTML(newHTML);
    const id = fragment.firstElementChild.id;
    morphdom(document.getElementById(id), fragment.firstElementChild);
  }

  parseHTML(html) {
    const template = document.createElement("template");
    template.innerHTML = html;
    return template.content.cloneNode(true);
  }
}
