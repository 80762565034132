import NProgress from "nprogress";
import { Controller } from "stimulus";
export default class extends Controller {
  start() {
    NProgress.start();
  }

  done() {
    NProgress.done();
  }
}
