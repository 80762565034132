import { Controller } from "stimulus";
import { FileInput, Informer, ProgressBar } from "uppy";
import { fileUpload, uploadedFileData } from "../src/admin/file_uploads.js";
export default class extends Controller {
  static targets = ["input", "fileName", "resultField"];
  fileInput;

  connect() {
    this.setupFileUpload();
  }

  setupFileUpload() {
    this.fileInput = this.inputTarget;
    this.element.removeChild(this.inputTarget);

    const uppy = fileUpload(this.fileInput, this.data.get("server"));

    uppy
      .use(FileInput, {
        target: this.element,
        locale: { strings: { chooseFiles: "Choose file" } },
        inputName: `${this.data.get("attribute")}_files[]`,
      })
      .use(Informer, {
        target: this.element,
      })
      .use(ProgressBar, {
        target: this.fileNameTarget,
        hideAfterFinish: false,
      });

    uppy.on("upload-success", (file, response) => {
      this.onUploadSuccess(file, response);
    });
  }

  onUploadSuccess(file, response) {
    const fileData = uploadedFileData(file, response, this.data.get("server"));

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    this.resultFieldTarget.value = fileData;

    // Set file name
    this.fileNameTarget.innerText = file.name;
  }
}
