window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  const maxFileSize = 20 * 1024 * 1024; //20MB
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert(
      "Only images (JPEG, GIF, and PNG) and PDF files can be uploaded.\nPlease convert the file or consider linking to a file on Google Drive.",
    );
  } else if (event.file.size > maxFileSize) {
    event.preventDefault();
    alert(
      "The file you attempted to attach is too large. Files must be under 20MB.\nConsider resizing the file, or linking to a file on Google Drive.",
    );
  }
});
