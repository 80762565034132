import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "countValue"];

  connect() {
    this.updateMessage();
  }

  updateMessage() {
    const count = this.inputTarget.value.length;
    let message = "";

    if (count > 0) {
      message = `${count}/${this.maxValue}`;
    }

    this.setMessage(message);
    this.updateMessageClass(count);
  }

  get maxValue() {
    return this.inputTarget.maxLength;
  }

  get warningClass() {
    return this.data.get("warning-class") || "text-warning";
  }

  get limitClass() {
    return this.data.get("limit-class") || "text-danger";
  }

  setMessage(message) {
    this.countValueTarget.textContent = message;
  }

  addWarningClass() {
    this.countValueTarget.classList.add(this.warningClass);
  }

  removeWarningClass() {
    this.countValueTarget.classList.remove(this.warningClass);
  }

  addLimitClass() {
    this.countValueTarget.classList.add(this.limitClass);
  }

  removeLimitClass() {
    this.countValueTarget.classList.remove(this.limitClass);
  }

  updateMessageClass(count) {
    if (count >= this.maxValue) {
      this.removeWarningClass();
      this.addLimitClass();
    } else if (count < this.maxValue && count > this.maxValue * 0.9) {
      this.removeLimitClass();
      this.addWarningClass();
    } else {
      this.removeWarningClass();
      this.removeLimitClass();
    }
  }
}
