import { Core, XHRUpload, AwsS3, AwsS3Multipart } from "uppy";

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: "cache",
  metadata: {
    size: file.size,
    filename: file.name,
    mime_type: file.type,
  },
});

export const uploadedFileData = (file, response, uploadServer) => {
  if (uploadServer == "s3") {
    // object key without prefix
    const id = file.meta["key"].match(/^cache\/(.+)/)[1];

    return JSON.stringify(fileData(file, id));
  } else if (uploadServer == "s3_multipart") {
    // object key without prefix

    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1];

    return JSON.stringify(fileData(file, id));
  } else {
    return JSON.stringify(response.body);
  }
};

export const fileUpload = (fileInput, uploadServer) => {
  const uppy = Core({
    id: fileInput.id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(","),
    },
  });

  if (uploadServer == "s3") {
    // Shrine's presign endpoint mounted on `/s3/params`
    uppy.use(AwsS3, {
      companionUrl: "/",
    });
  } else if (uploadServer == "s3_multipart") {
    // uppy-s3_multipart endpoint mounted on `/s3/multipart`
    uppy.use(AwsS3Multipart, {
      companionUrl: "/",
    });
  } else {
    // Shrine's upload endpoint
    uppy.use(XHRUpload, {
      endpoint: "/upload",
    });
  }

  return uppy;
};
